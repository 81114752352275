<template>
  <div class="book-cover-design-page main">
    <div class="bg-white">
      <div class="ss-container">
        <h6 class="section-title text-black">Book Cover Design</h6>
        <p class="section-desc text-black">書籍封面設計</p>
      </div>
    </div>
    <div class="bg-white white-section">
      <ListTemplate
        :data="bookCoverDesign"
        category="book-cover-design"
      ></ListTemplate>
    </div>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import BookCoverDesign from "@/data/bookCoverDesign.js";
export default {
  name: "BusinessDesign",
  components: {
    ListTemplate
  },
  data() {
    return {
      bookCoverDesign: BookCoverDesign
    };
  }
};
</script>

<style lang="sass" scoped>
.book-cover-design-page
  padding-top: 130px
  background-color: #f9f9f9
  +deviceWidth()
    padding-top: 50px
.white-section
  padding-bottom: 48px
  +deviceWidth()
    padding-bottom: 30px
</style>
